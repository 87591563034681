<template>
    <Navbar user="0" />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
export default {
  components: { Navbar },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      user:{},
      authError: null
    }
  },
  methods:{
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
          this.user = response.data
      }).catch(errors => {
         this.authError = errors.response.data.errors
      })
    },
  },
  created(){
      this.getUser()
  }
}
</script>

<style>

</style>